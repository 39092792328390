import { navigation, socials } from '@/navigation'
import { Link } from '@remix-run/react'
import logo from '/images/logo.png'
import { Button } from './common/button'
import { Container } from './common/container'
import { GithubIcon } from './icons/github'
import { MediumIcon } from './icons/medium'
import { XIcon } from './icons/x'

export const Footer = () => {
  return (
    <footer className="bg-black pt-10 pb-10 text-white xl:pb-20">
      <Container>
        <div className='grid items-center justify-center justify-items-center gap-6 text-center [grid-template-areas:"logo""copy""links""socials"] md:justify-between md:justify-items-start md:text-left md:[grid-template-areas:"logo_links""copy_socials"]'>
          <img src={logo} alt="Qubelabs" className="h-14 w-auto object-contain [grid-area:logo]" />
          <nav className="my-4 flex w-fit flex-wrap gap-x-6 gap-y-2 [grid-area:links] md:my-0">
            <Button variant="default" size="default" className="font-bold" asChild>
              <Link to={`#${navigation.learnMore}`}>About us</Link>
            </Button>
            <Button variant="default" size="default" className="font-bold" asChild>
              <Link to={`#${navigation.stake}`}>Stake</Link>
            </Button>
            <Button variant="default" size="default" className="font-bold" asChild>
              <Link to={`#${navigation.contact}`}>Contact</Link>
            </Button>
          </nav>
          <p className="max-w-xl text-white/70 [grid-area:copy]">
            Qubelabs is an independent organization of validators, builders, network contributors and community
            builders.
          </p>
          <div className="flex w-fit flex-wrap gap-4 justify-self-center [grid-area:socials] md:justify-self-end">
            <Button variant="square" size="icon" asChild>
              <a href={socials.x} target="_blank" rel="noreferrer">
                <XIcon />
              </a>
            </Button>
            <Button variant="square" size="icon" asChild>
              <a href={socials.github} target="_blank" rel="noreferrer">
                <GithubIcon />
              </a>
            </Button>
            <Button variant="square" size="icon" asChild>
              <a href={socials.medium} target="_blank" rel="noreferrer">
                <MediumIcon />
              </a>
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  )
}
